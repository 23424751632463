.tether-element {
  z-index: 10;
}

.spinning {
  -webkit-animation: __spinnerAnimation 2s linear infinite;
  animation: __spinnerAnimation 2s linear infinite; 
}

.__snackbar_message {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__navigation {
  background: none;
  font-size: 0.5em;
  border: none;
  top: 9px;
  outline: none;
}

.react-datepicker__navigation--previous {
  color: #fff;
  outline: none;
}

.react-datepicker__navigation--previous:after {
  position: absolute;
  left: 0px;
  font-size: 2rem;
  color: #424A50;
  outline: none;
}

.react-datepicker__navigation--next {
  color: #fff;
  outline: none;
}

.react-datepicker__navigation--next:after {
  position: absolute;
  right: 4px;
  font-size: 2rem;
  color: #424A50;
  outline: none;
}

.react-datepicker__header--time {
  ;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding: 0 5px 0 5px;
}

.logo {
  svg {
    width: 100px;
  }
}

.dropdown {
  &:after {
    content: '';
    width: 12px;
    height: 12px;
    background: white;
    transform: rotate(45deg);
    position: absolute;
    right: 26px;
    top: -6px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.13);
  }
  &:before {
    content: '';
    width: 20px;
    height: 12px;
    position: absolute;
    right: 22px;
    top: 0;
    background: white;
    z-index: 1;
  }
}

.circle {
  width: 16px;
  height: 16px;
  margin-right: -0.25rem;
  border-radius: 50%;
}

.drawer {
  width: 200px;
  height: 100vh;
}

.buttonRow {
  transition: all .2s linear;
  &:hover {
    background: #E9EBEE;
    border-left-color: #636871;
  }
}

.activeButtonRow {
  background: #E9EBEE;  
}

.activeChildNav {
  position: relative;
  &:after {
    content: '';
    background: white;
    width: 8px;
    height: 8px;
    position: absolute;
    right: -4px;
    top: calc(50% - 4px);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-left: 1px solid #E0E3E4;
    border-bottom: 1px solid #E0E3E4;
  }
}


.transferDropdown {
  width: 480px;
  min-height: 100px;
  margin-right: -1rem;
/*  ::after {
    content: '';
    position: absolute;
    top: -8px;
    right: 3rem;
    height: 16px;
    width: 16px;
    background: white;
    transform: rotate(45deg);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.13);
  }
  ::before {
    content: '';
    right: 2rem;
    top: 0;
    position: absolute;
    background: white;
    height: 16px;
    width: 3rem;
    z-index: 1;
  }*/
}

.progress {
  transition: width 0.1s linear;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}


@keyframes :global(__spinnerAnimation) {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(200deg);
  }
  100% {
    transform: rotate(360deg);
  }
}